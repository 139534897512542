<template>
  <BaseElInput
    v-loading="isLoading"
    class="cvs-select cursor-pointer"
    :value="observeValue"
    placeholder="若無填寫將退回原寄貨門市"
    suffix-icon="el-icon-plus"
    readonly
    @click.native="onSelectCVS"
  />
</template>

<script>
import { GetCVSMap, GetSelectCVS } from '@/api/ecommerce/delivery'
import { ref, computed, watch, onBeforeUnmount } from 'vue'

export default {
  name: 'CvsSelect',
  props: {
    value: { type: String },
    shopId: { type: String },
    cvsType: { type: String },
    configID: { type: String },
  },
  setup (props, { emit }) {
    const token = ref('')
    const link = ref('')
    const isLoading = ref(false)
    const ecpayWindow = ref(null)
    const timer = ref(null)
    const cvsInfo = ref({
      CVSStoreID: '',
      CVSStoreName: '',
      CVSAddress: '',
      CVSTelephone: '',
    })

    const observeValue = computed({
      get () {
        const { CVSStoreName } = cvsInfo.value
        if (!props.value) return ''
        return `${props.value} ${CVSStoreName}`
      },
      set (value) {
        emit('input', value)
      },
    })

    watch(() => props.cvsType, (value) => {
      if (!value) return
      GetCVSLink()
    })

    onBeforeUnmount(() => {
      clearInterval(timer.value)
    })

    const onSelectCVS = () => {
      const subType = props.cvsType
      if (!subType) return window.$message.error('請先選擇物流類型')
      if (!link.value) {
        window.$message.error('取得門市連結，請重新點選')
        GetCVSLink()
        return
      }

      if (ecpayWindow.value && !ecpayWindow.value.closed) {
        window.$message.error('你已開啟選擇門市代號視窗')
        ecpayWindow.value.close()
        ecpayWindow.value = null
      }
      clearInterval(timer.value)
      ecpayWindow.value = window.open(link.value)
      timer.value = setInterval(checkDeliveryStatus, 1000)
    }

    const checkDeliveryStatus = async (e) => {
      console.log('checkDeliveryStatus', ecpayWindow.value)
      if (!ecpayWindow.value) return
      if (!ecpayWindow.value.closed) return
      clearInterval(timer.value)
      const { shopId } = props
      const [res, error] = await GetSelectCVS({
        shopId,
        token: token.value,
      })
      if (error) return window.$message.error(error)
      if (!res.CVSStoreID) return
      cvsInfo.value = res
      observeValue.value = res.CVSStoreID
    }

    const fullscreenECPayWindow = () => {
      if (!ecpayWindow.value) return
      try {
        ecpayWindow.value.moveTo(0, 0)
        ecpayWindow.value.resizeTo(screen.width, screen.height)
      } catch (err) {
        console.log(err)
      }
    }

    const GetCVSLink = async () => {
      isLoading.value = true
      const subType = props.cvsType
      const { configID } = props
      const [res, error] = await GetCVSMap({
        shopId: props.shopId,
        subType,
        EcommerceDeliveryConfigId: configID,
      })
      isLoading.value = false
      if (error) return window.$message.error(error)
      token.value = res.token
      link.value = res.link
    }

    return {
      token,
      link,
      isLoading,
      ecpayWindow,
      timer,
      cvsInfo,
      observeValue,
      onSelectCVS,
      checkDeliveryStatus,
      fullscreenECPayWindow,
      GetCVSLink,
    }
  },
}
</script>

<style lang="scss">
.cvs-select {
  .el-input__inner {
    @apply cursor-pointer;
  }
}
</style>
